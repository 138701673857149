/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg';
import girl2 from '../assets/svg/testimonials/girl2.svg';
import girl3 from '../assets/svg/testimonials/girl3.svg';
import boy1 from '../assets/svg/testimonials/boy1.svg';
import boy2 from '../assets/svg/testimonials/boy2.svg';
import boy3 from '../assets/svg/testimonials/boy3.svg';

export const testimonialsData = [
  {
    id: 1,
    name: 'Chandler Bing',
    title: 'Transponster',
    text: "Building my own portfolio could've never been easier than this. The only areas I'm good at are Math, Jokes and Dance.",
    image: boy2,
  },
  {
    id: 2,
    name: 'Monica Geller',
    title: 'Head Chef',
    text: "This is the only thing that I didn't have to spend time to sort out. It's tidy all by itself.",
    image: girl2,
  },
  {
    id: 3,
    name: 'Phoebe Buffay',
    title: 'Massuese',
    text: "Heard of the song 'Smelly Cat!!' ? This website made it viral. Plus portfolio is the only thing that Ursula doesn't have :)",
    image: girl1,
  },
  {
    id: 4,
    name: 'Joey Tribbiani',
    title: 'Actor',
    text: "Making my portfolio with this helped me get my role as 'Dr. Drake Ramoray' in 'Days Of Our Lives'. You guys are the best !",
    image: boy1,
  },
  {
    id: 5,
    name: 'Rachel Green',
    title: 'Sales Executive',
    text: 'Ralph Lauren was one of the best things that ever happened to me. My portfolio made that dream happen. I owe you guys.',
    image: girl3,
  },
  {
    id: 6,
    name: 'Ross Geller',
    title: 'Paleontologist',
    text: "Be it 'Rocks' or 'Bones', you need a website to display it. This is it. Great work guys !",
    image: boy3,
  },
];
