export const blogData = [
    {
        id: 1,
        title: 'SCRAPING BILLBOARD AND IMDB USING PYTHON SELENIUM',
        description: 'Here we will use Selenium to navigate between web pages, and try to scrape data from them.',
        date: 'Aug 7, 2020',
        image: 'https://1.bp.blogspot.com/-6vZQoWcIZeo/Xvw5ZD5Y53I/AAAAAAAABgI/GH2b5OuMaXAKuigK4diBbeYDW6dD3qVvgCK4BGAsYHg/w400-h225/selenium.jpg',
        url: 'https://hackzism.blogspot.com/2020/07/scraping-billboard-and-imdb-using.html'
    },
    {
        id: 2,
        title: 'CHECK WEATHER FROM TERMINAL USING WTTR.IN',
        description: 'wttr.in is a console-oriented weather forecast service that supports various information representation methods like ANSI-sequences for console HTTP clients, HTML, or PNG.',
        date: 'Aug 11, 2020',
        image: 'https://1.bp.blogspot.com/-6vZQoWcIZeo/Xvw5ZD5Y53I/AAAAAAAABgI/GH2b5OuMaXAKuigK4diBbeYDW6dD3qVvgCK4BGAsYHg/w400-h225/selenium.jpg',
        url: 'https://hackzism.blogspot.com/2020/06/check-weather-from-terminal-using-wttrin.html'
    },
    {
        id: 3,
        title: 'BEING ANONYMOUS: A BEGINNERS GUIDE',
        description: 'Here are some basic ways to lay off the Radar',
        date: 'Aug 14, 2020',
        image: 'https://1.bp.blogspot.com/-GR19yCNbKCE/XuRcKEfKvhI/AAAAAAAAAwY/srFWw5pOpzMibeJb__SmgZpx9dIpNNWqgCK4BGAsYHg/w640-h426/anonymous1.jpg',
        url: 'https://hackzism.blogspot.com/2020/06/being-anonymous-beginners-guide.html'
    },
    {
        id: 4,
        title: 'CHECK INTERNET SPEED FROM THE COMMAND LINE',
        description: 'Want to check your internet speed from the terminal? Here\'s the perfect way to the that.',
        date: 'Sep 17, 2020',
        image: 'https://1.bp.blogspot.com/-XTVMsZP3HaM/Xxpl394txlI/AAAAAAAABws/zovXWiuNGg4TzBwYYVFfJ1ixDb3JfVokgCNcBGAsYHQ/w400-h195/speedtest.png',
        url: 'https://hackzism.blogspot.com/2020/07/check-internet-speed-from-command-line.html'
    },
    {
        id: 5,
        title: 'GET MICHAEL\'S OR TREVOR\'S VEHICLES FULLY UPGRADED IN GTA V FOR FREE',
        description: 'In case you haven\'t heard of this game, GTA stands for \'Grand Theft Auto\'. It is an open-world game published by Rockstar Games.',
        date: 'Oct 1, 2020',
        image: 'https://1.bp.blogspot.com/-jhdtUBIRD_s/XxLFKQuRz9I/AAAAAAAABsM/mClfbfDFKvsUwA7Wyi25Lzu3DEmL2lxrACNcBGAsYHQ/s1919/gta.jpg',
        url: 'https://hackzism.blogspot.com/2020/07/how-can-you-get-michaels-or-trevors.html'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/